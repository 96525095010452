<template>
  <div class="the-toolbar__content">
    <div class="max-content-toolbar px-5 px-lg-0">
      <v-row>
        <v-col cols="auto" class="pr-4 pr-sm-5 d-flex d-md-none py-5">
          <BaseButton icon @click.stop="$emit('click')">
            <v-icon color="purple100"> fa-solid fa-bars </v-icon>
          </BaseButton>
        </v-col>
        <v-col cols="4" md="2" lg="3" class="py-5 d-none d-sm-flex">
          <img
            src="@/assets/exclusiveLounge.svg"
            alt="Logo da empresa"
            class="pr-4 the-toolbar__img"
            @click="redirectTimeline"
          />
        </v-col>
        <v-spacer />
        <v-col cols="8" sm="5" md="4" lg="5" class="pr-5 pr-lg-12">
          <div :class="classButton('searchHome')" class="py-5">
            <BaseFilter
              v-model="filter"
              :placeholder="$t('search.title')"
              @keyup.enter="updateStoreFilter"
              @click="redirectSearch"
              @click:append-outer="updateStoreFilter"
              @click:clear="clearStoreFilter"
              :is-auto-focus="$route.name === 'searchHome'"
            />
          </div>
        </v-col>
        <v-col cols="auto" class="pr-0 pt-6 pb-5 pr-md-3">
          <BaseButton
            class="px-0 the-toolbar__button-new-post purple100"
            @click="openModal"
          >
            <img src="@/assets/addWhite.svg" />
          </BaseButton>
        </v-col>
        <v-col
          cols="auto"
          v-for="(item, index) in toolbarItems"
          :key="index"
          :class="classButton(item.route)"
          class="px-sm-1 pt-6 pb-5 d-none d-md-flex the-toolbar__button"
        >
          <BaseButton
            class="px-0 the-toolbar__button-new-post white"
            @click="redirectRoute(item.route)"
          >
            <img :src="imageButton(item)" />
            <v-badge
              color="orangeAlert"
              class="the-toolbar__badge"
              v-if="
                (item.route === 'notificationHome' && isNotification) ||
                (item.route === 'chatHome' && isMessageNotification) ||
                (item.route === 'profileHome' && notificationNewCommunity)
              "
              dot
            />
          </BaseButton>
          <v-card
            class="purple100 pa-2 the-toolbar__notification"
            v-if="item.route === 'profileHome' && isNewCommunity"
          >
            <p class="caption white--text mb-0">
              {{ $t("community.notification") }}
            </p>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script lang="ts">
import { TOOLBAR_BUTTONS } from "../mocks/toolbar";
import { Action, State } from "vuex-class";
import Component, { mixins } from "vue-class-component";
import { MiniProfile } from "@/modules/profile/types";
import { ActionMixin } from "../mixins/ActionMixin";
import { ModalMixin } from "../mixins/ModalMixin";
import { Prop, Watch } from "vue-property-decorator";
import { TokenService } from "@/api/storage";
@Component
export default class ToolbarProfile extends mixins(ActionMixin, ModalMixin) {
  @Prop(Boolean) isNotification: boolean;
  @Prop(Boolean) isMessageNotification: boolean;
  @Prop(Boolean) isNewCommunity: boolean;
  @Prop(Boolean) isProfile: boolean;
  toolbarItems = TOOLBAR_BUTTONS;
  modalNewAnnouncement = false;
  modalNewPost = false;
  filter = "";
  currentUser: MiniProfile = {
    id: 0,
    name: "",
    companyName: "",
    occupation: "",
    profileImage: "",
    role: "",
    hasMedal: false,
    userTheme: "",
    profileMedalType: "",
  };
  @State("currentFilter") currentFilter: any;
  @State((state) => state.community.notificationNewCommunity)
  notificationNewCommunity: any;
  @Action("updateFilter") updateFilter: any;
  @Action("profile/requestMe") requestMe: any;
  @Action("profile/requestUpdateTheme") requestUpdateTheme: any;
  @Action("updateFilterInput") updateFilterInput: any;
  @Action("onboarding/storeToken") storeToken: any;
  created(): void {
    this.filter = this.currentFilter;
    this.fetchMe();
  }
  async fetchMe(): Promise<void> {
    await this.withLoading(async () => {
      await this.requestMe().then((data: MiniProfile) => {
        this.currentUser = data;
        this.$vuetify.theme.dark = data.userTheme === "DARK";
      });
    });
  }
  imageButton(item: any): any {
    return this.$route.name === item.route ? item.imageSelected : item.image;
  }
  classButton(route: string): string {
    return this.$route.name === route ? "the-toolbar__col" : "";
  }
  redirectSearch(): void {
    if (this.$route.name !== "searchHome")
      this.$router.push({ name: "searchHome" });
  }
  updateStoreFilter(): void {
    if (!this.filter) return;
    this.updateFilter(!this.filter ? "" : this.filter);
    this.redirectSearch();
  }
  clearStoreFilter(): void {
    this.filter = "";
    this.updateFilter(!this.filter ? "" : this.filter);
    this.redirectSearch();
  }
  redirectRoute(route: string): void {
    this.$router.push({ name: route });
  }
  openModal(): void {
    if (TokenService.getRole() === "FOUR_NETWORK") {
      this.$router.push({ name: "newPostHome" });
    } else {
      this.$router.push({ name: "newAdvert" });
    }
  }
  closeModalNewPost(): void {
    this.modalNewPost = false;
    this.unblockScroll();
  }
  closeModalNewAnnouncement(): void {
    this.modalNewAnnouncement = false;
    this.unblockScroll();
  }
  async updateTheme(item: boolean): Promise<void> {
    const payload = `?theme=${item ? "DARK" : "LIGHT"}`;
    await this.withLoading(async () => {
      await this.requestUpdateTheme(payload);
    });
  }
  redirectTimeline(): void {
    if (this.$route.name !== "imelineHome")
      this.$router.push({ name: "timelineHome" });
  }
  @Watch("filter", { immediate: true, deep: true })
  updateValueFilter(): void {
    this.updateFilterInput(!this.filter ? "" : this.filter);
  }
}
</script>
<style lang="sass">
.the-toolbar__
  &content
    position: fixed
    min-width: 100% !important
    z-index: 100
    border-bottom: 1px solid var(--v-gray15-base) !important
    -webkit-backdrop-filter: blur(8px)
    backdrop-filter: blur(8px)
    background-color: rgba(255, 255, 255, 0.70) !important
  &col
    border-bottom: 2px solid var(--v-purple100-base) !important
  &common-button
    min-width: 40px !important
  &button-new-post
    width: 40px !important
    min-width: 40px !important
    max-width: 40px !important
    max-height: 40px !important
    border-radius: 12px !important
  &badge
    top: -3px
    left: -5px
    .v-badge__badge
      border-radius: 6px !important
      height: 12px !important
      min-width: 0
      padding: 0
      width: 12px !important
  &img
    cursor: pointer
  &notification
    position: absolute
    min-width: 307px !important
    right: 0
    top: 100px
    box-shadow: none !important
    border-radius: 0 !important
  //make arrow to div
  &notification::after
    content:''
    position: absolute
    right: 20px
    margin: 0 auto
    width: 0
    height: 0
    bottom:35px
    border-bottom: 8px solid var(--v-purple100-base) !important
    border-left: 5px solid transparent
    border-right: 5px solid transparent
  &button
    position: relative
  &width-profile
    width: 90% !important
    margin: 0 auto !important
    @media (max-width: 1290px)
      width: 95% !important
  &div-language
    width: 90px !important
  &language
    height: 20px !important
    width: 100% !important
    margin-top: 2px !important
    .v-select__selection--comma
      margin-top: 0 !important
      .v-text-field__slot .v-select__selections input,.v-text-field__slot .v-select__selections label, .v-text-field__slot .v-select__selections input::placeholder
      color: var(--v-purple100-base) !important
      font-weight: bold !important
      font-size: 14px !important
    .v-input__control > .v-input__slot:before
      border: none !important
    .v-input__control > .v-input__slot:after
      border: none !important
</style>
