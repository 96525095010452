<template>
  <div class="base-div" :class="color">
    <TheToolbar
      @click="toggleSidebar"
      :is-notification="isNotification"
      :is-message-notification="isMessageNotification"
      :is-new-community="isNewCommunity"
    />
    <TheSidebar :toggle="toggle" :mini="mini" class="d-flex d-md-none" />
    <v-main :class="color" class="main-padding">
      <TheAlert v-model="alertNotification" />
      <v-container app :class="padding">
        <slot />
      </v-container>
    </v-main>
    <LoadingModal />
  </div>
</template>
<script lang="ts">
import TheAlert from "../components/TheAlert.vue";
import { Prop } from "vue-property-decorator";
import Component, { mixins } from "vue-class-component";
import { State, Action } from "vuex-class";
import { TokenService } from "@/api/storage";
import { ActionMixin } from "../mixins/ActionMixin";
import LoadingModal from "../components/LoadingModal.vue";
import _ from "lodash";
import { api } from "@/api";
@Component({
  components: {
    TheAlert,
    LoadingModal,
  },
})
export default class Main extends mixins(ActionMixin) {
  @Prop({ default: "" }) title: any;
  @Prop({ default: "white" }) color: string;
  @Prop({ default: false }) routeFooter: boolean;
  @Prop({ default: "pa-4 px-md-0" }) padding: string;
  @Prop({ default: false }) button: boolean;
  @Prop({ default: "notification" }) actionButton: string;
  mini = false;
  toggle: any = null;
  isNotification = false;
  isMessageNotification = false;
  isNewCommunity = false;
  debounce = _.debounce(() => {
    this.fetchNotification();
  }, 60000);
  @Action("notification/requestIsNotification") requestIsNotification: any;
  @Action("notification/requestIsMessageNotification")
  requestIsMessageNotification: any;
  @Action("timeline/initializeAxios") initializeAxios: any;
  @State("alertNotification") alertNotification: any;
  @Action("community/requestIsNewCommunity") requestIsNewCommunity: any;
  @Action("community/requestRemoveNotificationCommunity")
  requestRemoveNotificationCommunity: any;
  get classContent(): string {
    return `${this.color} ${this.padding}`;
  }
  created(): void {
    this.$root.$i18n.locale = TokenService.getLanguage() || "pt";
    this.$root.$i18n.fallbackLocale = TokenService.getLanguage() || "pt";
    if (!api) {
      this.initializeAxios().then(() => {
        this.fetchData();
      });
    } else {
      this.fetchData();
    }
  }
  fetchData(): void {
    this.fetchNotification();
  }
  fetchNotification(): void {
    this.fetchIsNotification();
    this.fetchIsMessageNotification();
  }
  async fetchIsNotification(): Promise<void> {
    await this.withLoading(async () => {
      await this.requestIsNotification().then((data: boolean) => {
        this.isNotification = data;
      });
    });
  }
  async fetchIsMessageNotification(): Promise<void> {
    await this.withLoading(async () => {
      await this.requestIsMessageNotification().then((data: boolean) => {
        this.isMessageNotification = data;
        this.debounce();
      });
    });
  }
  toggleSidebar(): void {
    this.toggle = !this.toggle;
  }
  beforeDestroy(): void {
    this.debounce.cancel();
  }
}
</script>
<style lang="sass">
.container
  max-width: 100% !important
.main-padding
  padding-top: 85px !important
  @media(min-width: 900px)
    padding-left: 0px !important
.max-content-width
  @media(max-width: 600px)
    width: 100%
  @media(min-width: 600px)
    max-width: 600px
    min-width: 600px
    margin: 0 auto !important
  @media(min-width: 700px)
    max-width: 700px
    min-width: 700px
    margin: 0 auto !important
.max-content-toolbar
  @media(max-width: 600px)
    width: 100%
  @media(min-width: 600px)
    width: 100%
    margin: 0 auto !important
  @media(min-width: 960px)
    max-width: 960px
    min-width: 960px
    margin: 0 auto !important
  @media(min-width: 1135px)
    max-width: 1135px
    min-width: 1135px
    margin: 0 auto !important
.max-content-settings
  @media(max-width: 1050px)
    width: 100%
  @media(min-width: 1050px)
    max-width: 1050px
    min-width: 1050px
    margin: 0 auto !important
.max-content-table
  @media(max-width: 835px)
    width: 100%
  @media(min-width: 835px)
    max-width: 835px
    min-width: 835px
    margin: 0 auto !important
</style>
